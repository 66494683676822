import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import moment from 'moment';
import _ from 'lodash';
import { Button, Pagination, Row, Col, Form, DropdownButton, Dropdown } from 'react-bootstrap';
import { Confirm } from 'nave-components/dist/components/Modal/Confirm';
import classNames from 'classnames/bind';

import LinkAccountToPartner from './LinkAccountToPartner';
import TransferSubscription from './TransferSubscription';
import ExportTrialConfig from './ExportTrialConfig';
import ExecutiveDashboardAddOnConfig from './ExecutiveDashboardAddOnConfig';
import EditableLabel from '../../../components/EditableLabel';
import ChatFilter from './ChatFilter';
import ToggleSwitch from '../../../components/ToggleSwitch/ToggleSwitch';
import Loading from '../../../components/Loading/Loading';
import throttle from '../../../utils/throttle';
import pagination from '../../../utils/pagination';
import {
  getAccounts,
  updateEmail,
  updateHost,
  updateAllowedDashboards,
  updateRefId,
  deleteAccount,
  downloadAccountInvoices,
  handleSelectedAccounts,
  handleEachSelectedAccounts,
  switchAccountLogs
} from '../AccountsActions';
import { getChatFilter } from '../ChatFilterActions';

import settingIcon from '../../../assets/images/settings-gray-icon.svg';
import plusIcon from '../../../assets/images/plus-icon.svg';
import chatIcon from '../../../assets/images/chat-gray-icon.svg';
import trialIcon from '../../../assets/images/trial-icon.svg';
import deleteIcon from '../../../assets/images/delete-icon.svg';
import filterImage from '../../../assets/images/filter-icon.svg';

import styles from '../../../assets/styles/_table.scss';
import CheckBox from '../../../components/CheckBox/CheckBox';
let cx = classNames.bind(styles);

class AccountsTable extends Component {
  static propTypes = {
    pagination: PropTypes.bool,
    show: PropTypes.number,
  };

  static defaultProps = {
    pagination: true,
    show: 20,
  };

  constructor(props) {
    super(props);

    this.state = {
      page: 1,
      dateFormat: 'DD-MM-YYYY',
      platform: 'all',
      filter: 'all',
      sort: '-createdAt',
      search: '',
      isAllAccountsChecked: false,
      platformOptions: [
        {
          name: 'All',
          value: 'all',
        },
        {
          name: 'Jira',
          value: 'jira',
        },
        {
          name: 'Trello',
          value: 'trello',
        },
        {
          name: 'Asana',
          value: 'asana',
        },
        {
          name: 'Azure',
          value: 'azure',
        },
        {
          name: 'File',
          value: 'file',
        },
      ],
      filterOptions: [
        {
          name: 'All',
          value: 'all',
        },
        {
          name: 'Active Subscriptions',
          value: 'active',
        },
        {
          name: 'Canceled Subscriptions',
          value: 'cancelled',
        },
        {
          name: 'Canceled Subscriptions (>3m)',
          value: 'cancelledThan3Months',
        },
        {
          name: 'Trials',
          value: 'trials',
        },
        {
          name: 'Trials ending in next 3 days',
          value: 'endingtrial',
        },
        {
          name: 'Expired trials',
          value: 'expiredtrials',
        },
      ],
      sortOptions: [
        {
          name: 'Created at: newest first',
          value: '-createdAt',
        },
        {
          name: 'Created at: oldest first',
          value: 'createdAt',
        },
        {
          name: 'Name: A to Z',
          value: 'name',
        },
        {
          name: 'Name: Z to A',
          value: '-name',
        },
      ],
      isOpenSetting: false,
      selectSetting: 0,
      updateHostConfirm: {},
    };

    this.getAccountsThrottled = throttle(this.getAccounts, 500, this);
  }

  componentDidMount() {
    const { user } = this.props;
    this.getAccounts();
    if (user && user.role === 'admin' && !!user.partner && user.partner.role === 'owner') {
      this.props.getChatFilter();
    }
  }

  componentDidUpdate(prevProps) {
    const { user } = this.props;
    if (user && user !== prevProps.user && user.role === 'admin' && !!user.partner && user.partner.role === 'owner') {
      this.props.getChatFilter();
    }
  }

  handleChange = (ev) => {
    this.setState({ search: ev.target.value, page: 1 });
    this.getAccountsThrottled();
  };

  toggleFilter = () => {
    document.getElementById('filter-content').classList.toggle('shown');
  };

  applyPlatformFilter = (platform) => {
    this.setState({ platform, page: 1 }, this.getAccounts);
  };

  applyFilter = (filter) => {
    this.setState({ filter, page: 1 }, this.getAccounts);
  };

  applySort = (sort) => {
    this.setState({ sort, page: 1 }, this.getAccounts);
  };

  handleExportInvoices = (data) => {
    this.props.downloadAccountInvoices(data?.subscriptionId, data?.accountId, data?.email);
  };

  handleToggleLogs = (data) => {
    this.props.switchAccountLogs(data?.accountId, !data.enableLogMode);
  }

  goto = (page = 1) => {
    if (page < 1) {
      page = 1;
    }

    this.setState({ page }, this.getAccounts);
  };

  getAccounts = () => {
    const { platform, filter, sort, search, page } = this.state;
    this.props.getAccounts({
      platform,
      filter,
      sort,
      search,
      page,
    });
  };

  updateEmail = (userId, email) => {
    this.props.updateEmail(userId, email);
  };

  updateHost = (userId, host) => {
    this.setState({ updateHostConfirm: {} });
    this.props.updateHost(userId, host);
  };

  updateAllowedDashboards = (accountId, amount) => {
    this.props.updateAllowedDashboards(accountId, amount);
  };

  updateRefId = (accountId, refId) => {
    this.props.updateRefId(accountId, refId);
  };

  delete = (accountId) => {
    this.props.deleteAccount(accountId);
  };

  onSubscriptionTransferClose = (updated) => {
    if (updated) {
      this.getAccounts();
    }
  };

  renderPagination = () => {
    const pages = pagination(this.props.total, this.props.page, 20, 5);

    if (pages.totalPages < 2) {
      return <div></div>;
    }
    return (
      <Pagination>
        {pages.currentPage > 1 && <Pagination.First onClick={() => this.goto(1)} />}
        {pages.currentPage > 1 && <Pagination.Prev onClick={() => this.goto(pages.currentPage - 1)} />}
        {pages.pages.map((p, i) => {
          return (
            <Pagination.Item key={i} active={p === pages.currentPage} onClick={() => this.goto(p)}>
              {p}
            </Pagination.Item>
          );
        })}
        {pages.currentPage < pages.totalPages && <Pagination.Next onClick={() => this.goto(pages.currentPage + 1)} />}
        {pages.currentPage < pages.totalPages && <Pagination.Last onClick={() => this.goto(pages.totalPages)} />}
      </Pagination>
    );
  };

  showConfirm = (opt, onConfirm) => {
    return (
      <Confirm
        noBtn={true}
        visible={true}
        body={<p dangerouslySetInnerHTML={{ __html: opt.msg }}></p>}
        confirmText={opt.btnText}
        icon={opt.icon}
        title={opt.title}
        onConfirm={onConfirm}
        onClose={() => this.setState({ updateHostConfirm: {} })}
      />
    );
  };

  renderRow = (data, i) => {
    const isExpired = data.status === 'trial' && moment(data.trialEnd).isBefore(moment());

    return (
      <Row key={i} className={cx('row-item')}>
        <Col className={cx('col-selector')}>
          <Form.Group controlId="bulkSelect">
            <CheckBox
              name="isEachChecked"
              id="isEachChecked"
              checked={data?.isSelected}
              onChange={() => this.isEachAccountSelected(data)}
            />
          </Form.Group>
        </Col>
        {/* Col 1 */}
        <Col xs={12} md={4} className={cx('col-one')}>
          <div>
            <p>
              <label>Name:</label>
              <span>{data.name}</span>
            </p>
            <p>
              <label>Email:</label>
              {this.props.user.partner && this.props.user.partner.role === 'owner' && data.application === 'jira' ? (
                <EditableLabel
                  inputHeight="18px"
                  allowEmpty={false}
                  text={data.email}
                  onFocusOut={(email) => this.updateEmail(data.userId, email)}
                />
              ) : (
                <span>{data.email}</span>
              )}
            </p>
            {this.props.user.partner && this.props.user.partner.role === 'owner' && (
              <>
                <p>
                  <label>Partner:</label>
                  <span>{data.partner}</span>
                </p>
                <p>
                  <label>User Id:</label>
                  <span>{data.userId}</span>
                </p>
                {data.host && (
                  <p>
                    <label>Host URL:</label>
                    <EditableLabel
                      inputHeight="18px"
                      allowEmpty={false}
                      text={data.host}
                      onFocusOut={(host) => this.setState({ updateHostConfirm: { [data.userId]: host } })}
                    />
                  </p>
                )}
              </>
            )}
            {this.state.updateHostConfirm[data.userId] &&
              this.showConfirm(
                {
                  title: 'Update host',
                  msg: `Are you sure you want to change <b>${data.host}</b> to <b>${
                    this.state.updateHostConfirm[data.userId]
                  }</b>? Make sure there are no exisitng accounts with host <b>${
                    this.state.updateHostConfirm[data.userId]
                  }</b>`,
                  btnText: 'Update',
                  icon: settingIcon,
                },
                () => this.updateHost(data.userId, this.state.updateHostConfirm[data.userId])
              )}
          </div>
        </Col>

        {/* Col 2 */}
        <Col className={cx('col-two')}>
          <p>
            <label>Platform:</label>
            <span>{_.upperFirst(data.application)}</span>
          </p>
          {data.status !== 'partner' && (
            <p>
              <label>Referral No:</label>
              {this.props.user.partner && this.props.user.partner.role === 'owner' ? (
                <EditableLabel
                  allowEmpty={true}
                  text={data.refId}
                  onFocusOut={(ref) => this.updateRefId(data._id, ref)}
                  inputWidth="75px"
                  inputHeight="18px"
                />
              ) : (
                <span>{data.refId}</span>
              )}
            </p>
          )}
          {!!data.subscription ? (
            [
              <p key="plan">
                <label>Plan:</label>
                <span>{data.subscription.plan}</span>
              </p>,
              <p key="subtype">
                <label>Subscription type:</label>
                <span>{data.subscription.type === 'month' ? 'Monthly' : 'Annual'}</span>
              </p>,
            ]
          ) : (
            <p>
              <label>Status:</label>
              <span>{_.upperFirst(data.status)}</span>
            </p>
          )}
        </Col>

        {/* Col 3 */}
        <Col className={cx('col-three')}>
          <p>
            <label>Number of dashboards:</label>
            <span>{data.dashboards}</span>
          </p>
          {data.allowedDashboards > 0 && (
            <p>
              <label>Allowed dashboards:</label>
              {this.props.user.partner && this.props.user.partner.role === 'owner' ? (
                <EditableLabel
                  inputType="number"
                  min="0"
                  inputWidth="45px"
                  inputHeight="18px"
                  text={data.allowedDashboards}
                  onFocusOut={(amount) => this.updateAllowedDashboards(data._id, amount)}
                />
              ) : (
                <span>{data.allowedDashboards}</span>
              )}
            </p>
          )}
          {data?.status !== 'trial' && data?.payment?.status && (
            <p>
              <label>Payment Status:</label>
              <span>{data?.payment?.status?.join(', ')}</span>
            </p>
          )}
          {!!data.subscription && (
            <p>
              <label>Activated at:</label>
              <span>{moment(data.subscription.activatedAt).format(this.state.dateFormat)}</span>
            </p>
          )}
          {data?.lastAccessedOn && (
            <p>
              <label>Last access on:</label>
              <span>{moment(data.lastAccessedOn).format('L LT')}</span>
            </p>
          )}
        </Col>

        {/* Col 4 */}
        <Col className={cx('col-four')}>
          {data.status === 'active' && !!data.subscription ? (
            [
              <p key="rev">
                {this.props.user.partner && this.props.user.partner.role === 'owner' ? (
                  <>
                    <label>Revenue:</label>
                    <span>${(data.subscription.revenue / 100).toFixed(2)}</span>
                  </>
                ) : (
                  <>
                    <label>Quarterly Revenue:</label>
                    <span>${(data.subscription.quarterRevenue / 100).toFixed(2)}</span>
                  </>
                )}
              </p>,
              !!data.subscription.nextBilling ? (
                <p key="nextbill">
                  <label>Next billing:</label>
                  <span>{moment(data.subscription.nextBilling).format(this.state.dateFormat)}</span>
                </p>
              ) : (
                <p key="nextbill">
                  <label>Cancelling on:</label>
                  <span>{moment(data.subscription.scheduledCancellingOn).format(this.state.dateFormat)}</span>
                </p>
              ),
            ]
          ) : data.status === 'cancelled' ? (
            data.subscription && (
              <p>
                <label>Cancelled at:</label>
                <span>{moment(data.subscription.cancelledAt).format(this.state.dateFormat)}</span>
              </p>
            )
          ) : (
            <p>
              <label>Trial expire{isExpired ? 'd' : 's'} on:</label>
              <span>{moment(data.trialEnd).format(this.state.dateFormat)}</span>
            </p>
          )}
          {!!data.subscriptionId && (
            <p>
              <label>Sub:</label>
              <a target="_blank" rel="noopener noreferrer" href={data.subscriptionLink}>
                {data.subscriptionId}
              </a>
            </p>
          )}

          {!!data.isDeleting && (
            <p>
              <label>Status:</label>
              <span>Deleting</span>
            </p>
          )}
          {this.props.user.partner && this.props.user.partner.role === 'owner' &&(
            <p className="switch-component">
              <label>Enable Logs:</label>
              <span><ToggleSwitch toggled={data.enableLogMode} onToggle={() => this.handleToggleLogs(data)}  /></span>
            </p>
          )}         
        </Col>

        {/* Col 5 */}
        {this.props.user.partner && this.props.user.partner.role === 'owner' && (
          <Col className={cx('col-five')}>
            <Dropdown className="dropdown-settings-menu accounts">
              <Dropdown.Toggle className="btn-menu">
                <img src={settingIcon} alt="#" />
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <Dropdown.Item as={Link} to={`/accounts/${data.accountId}/dashboards`}>
                  Dashboards
                </Dropdown.Item>
                <Dropdown.Item as={Link} to={`/accounts/${data.accountId}/users`}>
                  Users
                </Dropdown.Item>
                {data?.subscriptionId && data?.status !== 'trial' && (
                  <Dropdown.Item onSelect={() => this.handleExportInvoices(data)}>Export invoices</Dropdown.Item>
                )}
                <Dropdown.Item
                  href={`https://${data.application}.getnave.com/api/account/login/${data.userId}`}
                  target="_blank"
                >
                  Remote login
                </Dropdown.Item>
                {data.status !== 'partner' && (
                  <Dropdown.Item>
                    <LinkAccountToPartner accountId={data._id} onClose={this.onSubscriptionTransferClose}>
                      <Button>Link to Partner</Button>
                    </LinkAccountToPartner>
                  </Dropdown.Item>
                )}
                {!!data.subscriptionId && (
                  <Dropdown.Item>
                    <TransferSubscription accountId={data._id} onClose={this.onSubscriptionTransferClose}>
                      <Button>Transfer subscription</Button>
                    </TransferSubscription>
                  </Dropdown.Item>
                )}
                <Dropdown.Item>
                  <Confirm
                    body={
                      <p>
                        Are you sure you want to delete <br />
                        this account <strong>{`${data.email}`}</strong>?
                      </p>
                    }
                    confirmText="Delete"
                    icon={deleteIcon}
                    title="Delete account"
                    onConfirm={() => this.delete(data.accountId)}
                  >
                    <Button disabled={data.isDeleting}>Delete account</Button>
                  </Confirm>
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
            <Dropdown className="dropdown-settings-menu nav-settings-dropdown mb-0">
              <Dropdown.Toggle className="btn-menu">
                <img src={plusIcon} alt="#" />
              </Dropdown.Toggle>
              <Dropdown.Menu>                
                <Dropdown.Item>
                  <ExecutiveDashboardAddOnConfig accountId={data._id}>
                    <Button>Executive Dashboard</Button>
                  </ExecutiveDashboardAddOnConfig>
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </Col>
        )}
      </Row>
    );
  };

  renderSelected = (type) => {
    const selected = _.find(this.state[type + 'Options'], {
      value: this.state[type],
    });
    return selected ? selected.name : '';
  };

  isAllSelected = ({ checked }) => {
    this.setState({
      isAllAccountsChecked: checked,
    });

    return this.props.handleSelectedAccounts(this.props.results, checked);
  };

  isEachAccountSelected = (selectedItem) => {
    this.props.handleEachSelectedAccounts(this.props.results, selectedItem);
  };

  handleBulkExport = (getSelectedAccounts) => {
    for (const data of getSelectedAccounts) {
      if (data?.status !== 'trial') {
        this.props.downloadAccountInvoices(data?.subscriptionId, data?.accountId, data?.email);
      }
    }
  };

  handleBulkDelete = (getSelectedAccounts) => {
    for (const data of getSelectedAccounts) {
      this.props.deleteAccount(data?.accountId);
    }
  };

  renderChatFilterButton = () => {
    const { chatFilter } = this.props;
    return (
      <ChatFilter model={chatFilter}>
        <div className={cx('nav-chat d-flex align-items-center ml-4 pr-0')}>
          <div className={cx('nav-chat-filter')}>
            <button className={cx('nav-chat-btn')}>
              <img src={chatIcon} alt="#" />
            </button>
          </div>
        </div>
      </ChatFilter>
    );
  };

  renderExportTrialButton = () => {
    return (
      <ExportTrialConfig>
        <div className={cx('nav-chat d-flex align-items-center ml-2 pr-0')}>
          <div className={cx('nav-chat-filter')}>
            <button className={cx('nav-chat-btn')}>
              <img src={trialIcon} alt="#" />
            </button>
          </div>
        </div>
      </ExportTrialConfig>
    );
  };

  render() {
    const { isLoading, results, total, pagination } = this.props;
    const isAllItemsChecked = results?.every((item) => item?.isSelected);

    const getSelectedAccounts = _.filter(results, (item) => item?.isSelected);

    return (
      <div>
        <div className={cx('table-content')}>
          <div className={cx('table-filter')}>
            <Form.Group
              controlId="bulkSelect"
              className={cx('bulk-select d-flex align-items-center justify-content-center pr-2')}
            >
              <CheckBox
                name="isAllChecked"
                id="isAllChecked"
                checked={isAllItemsChecked}
                onChange={(evt) => this.isAllSelected(evt)}
              />
            </Form.Group>

            <div className={cx('filter-content')} id="filter-content">
              <Form.Group controlId="platform" className="white filter-dropdown">
                <DropdownButton
                  className="form-dropdown"
                  id="dropdown-item-button"
                  title={this.renderSelected('platform')}
                >
                  {this.state.platformOptions.map((f, i) => {
                    return (
                      <Dropdown.Item
                        key={i}
                        as="button"
                        className={cx({
                          active: this.state.platform === f.value,
                        })}
                        onClick={() => this.applyPlatformFilter(f.value)}
                      >
                        {f.name}
                      </Dropdown.Item>
                    );
                  })}
                </DropdownButton>
                <Form.Label>
                  <span>Platform Filter</span>
                </Form.Label>
              </Form.Group>
              <Form.Group controlId="filter" className="white filter-dropdown">
                <DropdownButton
                  className="form-dropdown"
                  id="dropdown-item-button"
                  title={this.renderSelected('filter')}
                >
                  {this.state.filterOptions.map((f, i) => {
                    return (
                      <Dropdown.Item
                        key={i}
                        as="button"
                        className={cx({
                          active: this.state.filter === f.value,
                        })}
                        onClick={() => this.applyFilter(f.value)}
                      >
                        {f.name}
                      </Dropdown.Item>
                    );
                  })}
                </DropdownButton>
                <Form.Label>
                  <span>Status Filter</span>
                </Form.Label>
              </Form.Group>
              <Form.Group controlId="sort" className="white filter-dropdown">
                <DropdownButton className="form-dropdown" id="dropdown-item-button" title={this.renderSelected('sort')}>
                  {this.state.sortOptions.map((s, i) => {
                    return (
                      <Dropdown.Item
                        key={i}
                        as="button"
                        className={cx({ active: this.state.sort === s.value })}
                        onClick={() => this.applySort(s.value)}
                      >
                        {s.name}
                      </Dropdown.Item>
                    );
                  })}
                </DropdownButton>
                <Form.Label>
                  <span>Sort</span>
                </Form.Label>
              </Form.Group>
            </div>

            <Form.Group controlId="Search" className="u-di search-form white mr-2">
              <Form.Control
                type="text"
                name="search"
                placeholder="Search"
                onChange={this.handleChange}
                value={this.state.search}
              />
            </Form.Group>
            <div className={cx('filter-button')} onClick={this.toggleFilter}>
              <img alt="filter" src={filterImage} />
            </div>
            {this.props.user.partner && this.props.user.partner.role === 'owner' && this.renderChatFilterButton()}
            {this.props.user.partner && this.props.user.partner.role === 'owner' && this.renderExportTrialButton()}
            {this.props.user.partner && this.props.user.partner.role === 'owner' && (
              <div className="d-flex align-items-center ml-2 pr-0">
                <Dropdown className="dropdown-settings-menu nav-settings-dropdown mb-0 accounts">
                  <Dropdown.Toggle className="btn-menu nav-settings-icon">
                    <img src={settingIcon} alt="#" />
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    <Dropdown.Item onSelect={() => this.handleBulkExport(getSelectedAccounts)}>
                      Export invoices
                    </Dropdown.Item>
                    <Dropdown.Item>
                      <Confirm
                        body={
                          <p>
                            Are you sure you want to delete <strong>{`${getSelectedAccounts?.length}`}</strong>{' '}
                            {` account${getSelectedAccounts?.length > 1 ? 's' : ''}?`}
                          </p>
                        }
                        confirmText="Delete"
                        icon={deleteIcon}
                        title="Delete account"
                        onConfirm={() => this.handleBulkDelete(getSelectedAccounts)}
                      >
                        <Button>Delete accounts</Button>
                      </Confirm>
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            )}
          </div>

          {isLoading && (
            <div className={cx('table-loading')}>
              <Loading />
            </div>
          )}
          {!isLoading && results?.length === 0 && (
            <div className={cx('no_data')}>
              <p> No data available </p>
            </div>
          )}

          <div className={cx('table-list')}>
            {!isLoading &&
              results &&
              _.map(_.take(this.props.results, this.props.show), (data, i) => this.renderRow(data, i))}
          </div>
        </div>
        {total > 0 &&
          (!!pagination ? (
            this.renderPagination()
          ) : (
            <div className={cx('load-more')}>
              <Link to="/accounts" className="btn btn-dark-blue">
                View more
              </Link>
            </div>
          ))}
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    getAccounts: (opt) => dispatch(getAccounts(opt)),
    handleSelectedAccounts: (results, isSelected) => dispatch(handleSelectedAccounts(results, isSelected)),
    handleEachSelectedAccounts: (results, selectedItem) => dispatch(handleEachSelectedAccounts(results, selectedItem)),
    updateEmail: (userId, email) => dispatch(updateEmail(userId, email)),
    downloadAccountInvoices: (subscriptionId, accountId, invoiceName) =>
      dispatch(downloadAccountInvoices(subscriptionId, accountId, invoiceName)),
    updateHost: (userId, host) => dispatch(updateHost(userId, host)),
    updateAllowedDashboards: (accountId, amount) => dispatch(updateAllowedDashboards(accountId, amount)),
    updateRefId: (accountId, refId) => dispatch(updateRefId(accountId, refId)),
    deleteAccount: (id) => dispatch(deleteAccount(id)),
    getChatFilter: () => dispatch(getChatFilter()),
    switchAccountLogs: (accountId, enable) => dispatch(switchAccountLogs(accountId, enable))
  };
};

function mapStateToProps(state) {
  return {
    results: state.accounts.results,
    isLoading: state.accounts.isLoading,
    chatFilter: state.chat.filter,
    page: state.accounts.page,
    total: state.accounts.total,
    user: state.auth.user,
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(AccountsTable);
