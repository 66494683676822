import React from 'react';
import classNames from 'classnames/bind';
import styles from './ToggleSwitch.scss';
let cx = classNames.bind(styles);

const ToggleSwitch = ({ toggled, onToggle }) => {
  const handleToggle = () => {
    onToggle(!toggled); 
  };

  return (
    <div className={cx('switch_box')}>
      <label className={cx('switch')}>
        <input
          type="checkbox"
          checked={toggled} 
          onChange={handleToggle}
        />
        <span className={cx('slider', 'round')}></span>
      </label>      
    </div>
  );
};

export default ToggleSwitch;