import _ from 'lodash';
import { PDFDocument } from 'pdf-lib';
import moment from 'moment';

import apiCaller from '../../utils/apiCaller';
import urlHelper from '../../utils/url';
import errorHandler from '../Common/ErrorHandler';

const Json2csvParser = require('json2csv').Parser;

// Export Constants
export const IS_LOADING = 'ACCOUNTS_IS_LOADING',
  LIST = 'ACCOUNTS_LIST',
  DASHBOARDS = 'ACCOUNTS_DASHBOARDS',
  USERS = 'USERS_DASHBOARDS';

const URL_REGEX = /^(http|https):\/\/|[a-z0-9]+([-.]{1}[a-z0-9]+)*\.[a-z]{2,6}(:[0-9]{1,5})?(\/.*)?$/gi;

export function handleEachSelectedAccounts(results, selectedItem) {
  return (dispatch, getState) => {
    const accounts = _.map(results, (item) => {
      if (item?._id === selectedItem?._id) {
        return {
          ...item,
          isSelected: !selectedItem?.isSelected,
        };
      }

      return {
        ...item,
      };
    });

    const acc = getState().accounts;

    dispatch({
      type: LIST,
      payload: {
        ...acc,
        results: accounts,
      },
    });
  };
}

export function handleSelectedAccounts(results, isSelected) {
  return (dispatch, getState) => {
    const accounts = _.map(results, (item) => {
      return {
        ...item,
        isSelected,
      };
    });

    const acc = getState().accounts;

    dispatch({
      type: LIST,
      payload: {
        ...acc,
        results: accounts,
      },
    });
  };
}

export function getAccounts(opt = {}, noDispatch) {
  return (dispatch) => {
    !noDispatch && dispatch({ type: IS_LOADING });

    let url = 'accounts';
    url += urlHelper.buildQuery(opt);

    return apiCaller
      .api(url, 'get')
      .then((res) => {
        if (noDispatch) {
          return res;
        }

        dispatch({
          type: LIST,
          payload: res,
        });
      })
      .catch((err) => {
        dispatch(errorHandler.setError(err));
      });
  };
}

export function getDashboards(id, opt = {}) {
  return (dispatch) => {
    if (!id) {
      return dispatch(errorHandler.setError('Account ID is required'));
    }

    dispatch({ type: IS_LOADING });

    let url = `accounts/${id}/dashboards`;
    // url += urlHelper.buildQuery(opt);

    apiCaller
      .api(url, 'get')
      .then((res) => {
        dispatch({
          type: DASHBOARDS,
          payload: res,
        });
      })
      .catch((err) => {
        dispatch(errorHandler.setError(err));
      });
  };
}

export function getUsers(id, opt = {}) {
  return (dispatch) => {
    if (!id) {
      return dispatch(errorHandler.setError('Account ID is required'));
    }

    dispatch({ type: IS_LOADING });

    let url = `accounts/${id}/users`;

    apiCaller
      .api(url, 'get')
      .then((res) => {
        dispatch({
          type: USERS,
          payload: res,
        });
      })
      .catch((err) => {
        dispatch(errorHandler.setError(err));
      });
  };
}

export function downloadTrials(startDate, endDate) {
  return async (dispatch, getState) => {
    return apiCaller
      .api('accounts/download-trial-csv', 'post', { startDate, endDate })
      .then(async (res) => {
        try {
          dispatch(errorHandler.setSuccessMessage('Your export is being processed and it will start automatically '));
          const fields = [
            { label: 'status', value: 'status' },
            { label: 'application', value: 'application' },
            { label: 'email', value: 'email' },
            { label: 'createdAt', value: 'createdAt' },
            { label: 'lastActiveOn', value: 'lastActiveOn' },
            { label: 'dashboards', value: 'dashboards' },
            { label: 'users', value: 'users' },
            { label: 'subscription', value: 'subscription' },
          ];
          const json2csvParser = new Json2csvParser({ fields });
          const data = json2csvParser.parse(res.results);
          const filename = `csv_${moment(startDate).format('YYYY-M-D')}_${moment(endDate).format('YYYY-M-D')}.csv`;
          const blob = new Blob([data], { type: 'text/csv;charset=utf-8;' });
          const url = URL.createObjectURL(blob);
          download(url, filename);
        } catch (err) {
          dispatch(errorHandler.setError(err));
        }
      })
      .catch((err) => {
        dispatch(errorHandler.setError(err));
      });
  };
}

export function downloadAccountInvoices(subscriptionId, accountId, invoiceName) {
  return (dispatch, getState) => {
    if (!subscriptionId) {
      dispatch(errorHandler.setError('Subscription is required to download invoice'));
      return Promise.reject();
    }

    dispatch(errorHandler.setSuccessMessage('Your export is being processed and it will start automatically '));

    return apiCaller
      .api('accounts/invoices/download-pdf', 'post', { subscriptionId, accountId })
      .then(async (res) => {
        const pdfDoc = await PDFDocument.create();

        for (const pdfUrl of res?.downloadUrls) {
          const response = await fetch(pdfUrl);
          const pdfData = await response.arrayBuffer();
          const pdf = await PDFDocument.load(pdfData);

          const copiedPages = await pdfDoc.copyPages(pdf, pdf.getPageIndices());
          copiedPages.forEach((page) => pdfDoc.addPage(page));
        }

        const mergedPdfBytes = await pdfDoc.save();

        const blob = new Blob([mergedPdfBytes], { type: 'application/pdf' });
        const url = URL.createObjectURL(blob);
        download(url, `${invoiceName}-invoices.pdf`);
      })
      .catch((err) => {
        dispatch(errorHandler.setError(err));
      });
  };
}

export default function download(url, filename) {
  const link = document.createElement('a');
  link.setAttribute('href', url);
  link.setAttribute('download', filename);
  link.setAttribute('target', '_blank');

  link.setAttribute('style', 'visibility:hidden');
  link.style.visibility = 'hidden';
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
  URL.revokeObjectURL(url);
}

export function updateEmail(userId, email) {
  return (dispatch, getState) => {
    if (!userId) {
      dispatch(errorHandler.setError('User ID is required'));
      return Promise.reject();
    }

    if (!email) {
      dispatch(errorHandler.setError('Email is required'));
      return Promise.reject();
    }

    return apiCaller
      .api(`accounts/update/email`, 'put', { userId, email })
      .then((res) => {
        const acc = getState().accounts;
        const index = _.findIndex(acc.results, { userId });
        if (index > -1) {
          acc.results[index].email = email;
          acc.results.splice(index, 1, acc.results[index]);
        }

        dispatch(errorHandler.setSuccessMessage('Email successfully changed'));
        dispatch({
          type: LIST,
          payload: {
            results: [...acc.results],
            total: acc.total,
            page: acc.page,
          },
        });
      })
      .catch((err) => {
        dispatch(errorHandler.setError(err));
      });
  };
}

export function updateHost(userId, host) {
  return (dispatch, getState) => {
    if (!userId) {
      dispatch(errorHandler.setError('User ID is required'));
      return Promise.reject();
    }

    if (!host) {
      dispatch(errorHandler.setError('Host is required'));
      return Promise.reject();
    }

    if (!URL_REGEX.test(host)) {
      dispatch(errorHandler.setError('Host is invalid'));
      return Promise.reject();
    }

    return apiCaller
      .api(`accounts/update/host`, 'put', { userId, host })
      .then((res) => {
        const acc = getState().accounts;
        const index = _.findIndex(acc.results, { userId });
        if (index > -1) {
          acc.results[index].host = host;
          acc.results.splice(index, 1, acc.results[index]);
        }

        dispatch(errorHandler.setSuccessMessage('Host successfully changed'));
        dispatch({
          type: LIST,
          payload: {
            results: [...acc.results],
            total: acc.total,
            page: acc.page,
          },
        });
      })
      .catch((err) => {
        dispatch(errorHandler.setError(err));
      });
  };
}

export function updateAllowedDashboards(accountId, amount) {
  return (dispatch, getState) => {
    if (!accountId) {
      dispatch(errorHandler.setError('Account ID is required'));
      return Promise.reject();
    }

    if (!amount) {
      dispatch(errorHandler.setError('Amount is required'));
      return Promise.reject();
    }

    if (amount > 1000) {
      dispatch(errorHandler.setError('Allowed dashboards should be less than 1000'));
      return Promise.reject();
    }

    return apiCaller
      .api(`accounts/update/dashboards`, 'put', { accountId, amount })
      .then((res) => {
        const acc = getState().accounts;
        const index = _.findIndex(acc.results, { _id: accountId });
        if (index > -1) {
          acc.results[index].allowedDashboards = amount;
          acc.results.splice(index, 1, acc.results[index]);
        }

        dispatch(errorHandler.setSuccessMessage('Allowed dashboards successfully changed'));
        dispatch({
          type: LIST,
          payload: {
            results: [...acc.results],
            total: acc.total,
            page: acc.page,
          },
        });
      })
      .catch((err) => {
        dispatch(errorHandler.setError(err));
      });
  };
}

export function updateRefId(accountId, refId) {
  return (dispatch, getState) => {
    if (!accountId) {
      return dispatch(errorHandler.setError('Account ID is required'));
    }

    return apiCaller
      .api(`accounts/update/refid`, 'put', { accountId, refId })
      .then((res) => {
        const acc = getState().accounts;
        const index = _.findIndex(acc.results, { _id: accountId });
        if (index > -1) {
          acc.results[index] = res;
          acc.results.splice(index, 1, acc.results[index]);
        }

        dispatch(errorHandler.setSuccessMessage('Ref ID successfully changed'));
        dispatch({
          type: LIST,
          payload: {
            results: [...acc.results],
            total: acc.total,
            page: acc.page,
          },
        });
      })
      .catch((err) => {
        dispatch(errorHandler.setError(err));
      });
  };
}

export function tranferSubscription(fromAccId, toAccId) {
  return (dispatch, getState) => {
    if (!fromAccId || !toAccId) {
      dispatch(errorHandler.setError('Account ID is required'));
      return Promise.reject();
    }

    return apiCaller
      .api(`accounts/update/subscription`, 'put', { fromAccId, toAccId })
      .then((res) => {
        dispatch(errorHandler.setSuccessMessage('Subscription successfully transfered'));
      })
      .catch((err) => {
        dispatch(errorHandler.setError(err));
      });
  };
}

export function linkAccToPartner(accountId, partnerUserId) {
  return (dispatch, getState) => {
    if (!accountId) {
      dispatch(errorHandler.setError('Account ID is required'));
      return Promise.reject();
    }

    if (!partnerUserId) {
      dispatch(errorHandler.setError('Partner ID is required'));
      return Promise.reject();
    }

    return apiCaller
      .api(`/accounts/linktopartner`, 'put', { accountId, partnerUserId })
      .then((res) => {
        dispatch(errorHandler.setSuccessMessage('Account successfully linked to partner'));
      })
      .catch((err) => {
        dispatch(errorHandler.setError(err));
      });
  };
}

export function deleteAccount(accountId) {
  return (dispatch, getState) => {
    apiCaller
      .api(`accounts/${accountId}`, 'delete')
      .then((res) => {
        const acc = getState().accounts;
        const index = _.findIndex(acc.results, { _id: accountId });
        if (index > -1) {
          acc.results[index].isDeleting = true;
          acc.results.splice(index, 1, acc.results[index]);
        }

        dispatch({
          type: LIST,
          payload: {
            results: [...acc.results],
            total: acc.total - 1,
            page: acc.page,
          },
        });
      })
      .catch((err) => {
        dispatch(errorHandler.setError(err));
      });
  };
}

export function transferOwnership(accId, userId) {
  return (dispatch) => {
    if (!accId) {
      return dispatch(errorHandler.setError('Account ID is required'));
    }
    if (!userId) {
      return dispatch(errorHandler.setError('User ID is required'));
    }

    apiCaller
      .api(`accounts/${accId}/${userId}`, 'put')
      .then((res) => {
        dispatch(errorHandler.setSuccessMessage('Account ownership successfully transferred'));
        dispatch(getUsers(accId));
      })
      .catch((err) => {
        dispatch(errorHandler.setError(err));
      });
  };
}

export function cleanupDashboard(accId, dashboardId) {
  return (dispatch) => {
    if (!accId) {
      return dispatch(errorHandler.setError('Account ID is required'));
    }
    if (!dashboardId) {
      return dispatch(errorHandler.setError('Dashboard ID is required'));
    }

    apiCaller
      .api(`accounts/${accId}/dashboards/${dashboardId}/clean`, 'post')
      .then((res) => {
        dispatch(errorHandler.setSuccessMessage('Dashboard cleaning up job successfully created'));
      })
      .catch((err) => {
        dispatch(errorHandler.setError(err));
      });
  };
}

export function resyncDashboard(accId, dashboardId, wipeOut) {
  return (dispatch) => {
    if (!accId) {
      return dispatch(errorHandler.setError('Account ID is required'));
    }
    if (!dashboardId) {
      return dispatch(errorHandler.setError('Dashboard ID is required'));
    }

    apiCaller
      .api(`accounts/${accId}/dashboards/${dashboardId}/resync`, 'post', wipeOut ? { wipeOut: true } : undefined)
      .then((res) => {
        dispatch(errorHandler.setSuccessMessage('Dashboard resyncing job successfully created'));
      })
      .catch((err) => {
        dispatch(errorHandler.setError(err));
      });
  };
}

export function updateConfigAddOnED(accountId, config) {
  return (dispatch) => {
    if (!accountId) {
      return dispatch(errorHandler.setError('Account ID is required'));
    }
    
    return apiCaller
      .api(`/accounts/${accountId}/configAddons/ed`, 'post', { config })
      .then(() => {
        dispatch(errorHandler.setSuccessMessage('Executive Dashboards Add-on successfully updated'));
      })
      .catch((err) => {
        dispatch(errorHandler.setError(err));
      });
  };
}

export function switchAccountLogs(accountId, enabled) {
  return (dispatch, getState) => {
    if (!accountId) {
      return dispatch(errorHandler.setError('Account ID is required'));
    }
    
    return apiCaller
      .api(`/accounts/${accountId}/config/logs`, 'post', { enableLogMode: enabled })
      .then((res) => {
        const acc = getState().accounts;
        const index = _.findIndex(acc.results, { _id: accountId });
        if (index > -1) {
          acc.results[index].enableLogMode = res.enableLogMode;          
        }
        dispatch(errorHandler.setSuccessMessage('Account logs successfully updated'));
        dispatch({
          type: LIST,
          payload: {
            results: [...acc.results],
            total: acc.total,
            page: acc.page,
          },
        });        
      })
      .catch((err) => {
        dispatch(errorHandler.setError(err));
      });
  };
}

export function getAccountConfig(id) {
  return (dispatch) => {
    if (!id) {
      return dispatch(errorHandler.setError('Account ID is required'));
    }

    return apiCaller
      .api(`accounts/${id}/config`, 'get')
      .then((res) => {
        return res;
      })
      .catch((err) => {
        dispatch(errorHandler.setError(err));
      });
  };
}
