export function buildQuery(opt) {
  let url = `?page=${opt.page || 1}`;

  if (opt.sort) {
    url += `&sort=${opt.sort}`;
  }
  if (opt.app) {
    url += `&app=${opt.app}`;
  }
  if (opt.platform) {
    url += `&platform=${opt.platform}`;
  }
  if (opt.filter) {
    url += `&filter=${opt.filter}`;
  }
  if (opt.search) {
    url += `&search=${opt.search}`;
  }
  if (opt.pageSize) {
    url += `&pageSize=${opt.pageSize}`;
  }
  if (opt.limit) {
    url += `&limit=${opt.limit}`;
  }
  if (opt.subs) {
    url += `&subs=${opt.subs}`;
  }
  if (opt.webhook) {
    url += `&webhook=${opt.webhook}`;
  }
  if (opt.weekly) {
    url += `&weekly=${opt.weekly}`;
  }
  if (opt.pulling) {
    url += `&pulling=${opt.pulling}`;
  }
  if (opt.audit) {
    url += `&audit=${opt.audit}`;
  }

  return url;
}

export default {
  buildQuery,
};
