import React, { Component } from 'react';
import { withRouter } from 'react-router';
import Helmet from 'react-helmet';
import { Container } from 'react-bootstrap';
import { connect } from 'react-redux';
import classNames from 'classnames/bind';
import _ from 'lodash';
import { Button, Row, Col, Modal, Dropdown } from 'react-bootstrap';
import { Confirm } from 'nave-components/dist/components/Modal/Confirm';

import Loading from '../../components/Loading/Loading';
import { getDashboards, resyncDashboard, cleanupDashboard } from './AccountsActions';

import settingIcon from '../../assets/images/settings-gray-icon.svg';
import resyncIcon from '../../assets/images/resync.svg';
import deleteIcon from '../../assets/images/delete-icon.svg';
import styles from '../../assets/styles/_table.scss';
let cx = classNames.bind(styles);

class Dashboards extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  componentDidMount() {
    this.props.getDashboards(this.props.match.params.id);
  }

  renderRow = (data, i) => {
    return (
      <Row key={i} className={cx('row-item')}>
        {/* Col 1 */}
        <Col xs={12} md={6}>
          <p>
            <label>Name:</label>
            <span>{data.name}</span>
          </p>
          <p>
            <label>Board:</label>
            <span>{data.board}</span>
          </p>

          {!!data.selectedArea && (
            <p>
              <label>Area Path:</label>
              <span>{data.selectedArea}</span>
            </p>
          )}
        </Col>

        <Col xs={12} md={5}>
          <p>
            <label>Id:</label>
            <span>{data._id}</span>
          </p>
        </Col>

        {/* Col 5 */}
        <Col className={cx('col-five')}>
          <Dropdown className="dropdown-settings-menu">
            <Dropdown.Toggle className="btn-menu">
              <img src={settingIcon} alt="#" />
            </Dropdown.Toggle>
            <Dropdown.Menu>
              <Dropdown.Item>
                <Button onClick={() => this.setState({ [`modal_${i}`]: true })}>Open dashboard mapping</Button>
              </Dropdown.Item>
              <Dropdown.Item>
                <Confirm
                  body={
                    <p>
                      Are you sure you want to clean up <br />
                      {data.name} dashboard?
                    </p>
                  }
                  confirmText="Clean up"
                  icon={deleteIcon}
                  title="Clean up dashboard"
                  onConfirm={() => this.props.cleanup(this.props.match.params.id, data._id)}
                >
                  <Button>Clean up dashboard</Button>
                </Confirm>
              </Dropdown.Item>
              <Dropdown.Item>
                <Confirm
                  body={
                    <p>
                      Are you sure you want to resync <br />
                      {data.name} dashboard?
                    </p>
                  }
                  confirmText="Resync"
                  icon={resyncIcon}
                  title="Resync dashboard"
                  onConfirm={() => this.props.resync(this.props.match.params.id, data._id)}
                >
                  <Button>Resync dashboard</Button>
                </Confirm>
              </Dropdown.Item>
              <Dropdown.Item>
                <Confirm
                  body={
                    <p>
                      Are you sure you want to wipe out <br />
                      {data.name} dashboard?
                    </p>
                  }
                  confirmText="Wipe out"
                  icon={resyncIcon}
                  title="Wipe out dashboard"
                  onConfirm={() => this.props.wipeout(this.props.match.params.id, data._id)}
                >
                  <Button>Wipe out dashboard</Button>
                </Confirm>
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </Col>

        <Modal show={this.state[`modal_${i}`]} onHide={() => this.setState({ [`modal_${i}`]: false })}>
          <Modal.Header closeButton>
            <h2 className="mb-0">Open dashboard mapping</h2>
          </Modal.Header>
          <Modal.Body>
            <div>
              <Row className={cx('row-item')}>
                {/* Col 2 */}
                <Col className="col-border-right">
                  {_.map(data.todo, (l, li) => {
                    return (
                      <p>
                        <span key={li}>{l}</span>
                      </p>
                    );
                  })}
                </Col>

                {/* Col 3 */}
                <Col className="col-border-right">
                  {_.map(data.wip, (l, li) => {
                    return (
                      <p>
                        <span key={li}>{l}</span>
                      </p>
                    );
                  })}
                </Col>

                {/* Col 4 */}
                <Col className="col-border-right">
                  {_.map(data.done, (l, li) => {
                    return (
                      <p>
                        <span key={li}>{l}</span>
                      </p>
                    );
                  })}
                </Col>
              </Row>
            </div>
          </Modal.Body>
        </Modal>
      </Row>
    );
  };

  render() {
    const { isLoading, dashboards } = this.props;
    return [
      <Helmet key="0" title="Dashboards" />,
      <Container key="1">
        <div className={cx('table-content')}>
          {isLoading && (
            <div className={cx('table-loading')}>
              <Loading />
            </div>
          )}
          {!isLoading && dashboards.length === 0 && (
            <div className={cx('no_data')}>
              <p> No data available </p>
            </div>
          )}

          <div className={cx('table-list no-padding')}>
            {!isLoading && dashboards && _.map(dashboards, (data, i) => this.renderRow(data, i))}
          </div>
        </div>
      </Container>,
    ];
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    getDashboards: (id, opt) => dispatch(getDashboards(id, opt)),
    resync: (accId, dashboardId) => dispatch(resyncDashboard(accId, dashboardId)),
    cleanup: (accId, dashboardId) => dispatch(cleanupDashboard(accId, dashboardId)),
    wipeout: (accId, dashboardId) => dispatch(resyncDashboard(accId, dashboardId, true)),
  };
};

function mapStateToProps(state) {
  return {
    dashboards: state.accounts.dashboards,
    isLoading: state.accounts.isLoading,
  };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Dashboards));
